<template>
  <div class="_page-content">
    <ax-table ref="tableBox" :show-search="true" :searchForm="searchForm"
      :searchActions='searchActions' :columns="columns" :dataSourceApi="api.deptQueryTree"
      :sqlParams="sqlParams" :dataSourceParams="dataSourceParams" :clickable="false"
      :scroll="{ y: '55vh', x: '80vw' }"
      @action-column-click="actionColumnClick" @add="handleAdd" @on-search-click="onSearchClick">
      <template #autographUrl="{record}">
        <div class="table-ellipsis" style="max-height: 100px;">
          <img style="height:100%;width:100%;" @click="show(record.autographUrl)"
            :src="record.autographUrl">
        </div>
      </template>
    </ax-table>

    <uploadSignature ref="uploadSignature" @closeModal="handleCloseModal" autographType="2" />
    <a-modal v-model="visible" title="签章" :footer="null" width="50%">
      <img style="height:100%;width:100%;" :src="autographUrl">
    </a-modal>

    <editSignature ref="editSignature" :editApi="api.deptUpdate"
      :selectApi="api.updateDeptAutograph" @closeModal="handleCloseModal" autographType="2" />

    <viewSignature ref="viewSignature" @closeModal="handleCloseModal" autographType="2" />
  </div>
</template>

<script>
import api from './user'
import uploadSignature from './uploadSignature.vue'
import editSignature from './editSignature.vue'
import viewSignature from './viewSignature.vue'

export default {
  name: 'TestAxTable',
  components: {
    uploadSignature,
    editSignature,
    viewSignature
  },
  data () {
    return {
      api,
      visible: false,
      dataSourceParams: {
      },
      autographUrl: '',
      // 树形部分
      columns: this.$ax.tools.initColumn([
        {
          title: '部门',
          dataIndex: 'deptName',
          ellipsis: true
        },
        {
          title: '签章',
          dataIndex: 'autographUrl'
        }
      ], true, {
        width: 150,
        actions: [
          { props: record => { return { text: '编辑', name: 'handleEdit', type: '#3853db', link: true, loading: record.id === 0 } } }
        ]
      }),
      searchActions: [
        { name: 'add', text: '上传签章' },
        { name: 'search', text: '查询' },
        { name: 'reset', text: '重置' },
        { name: 'view', text: '查看签章' }
      ],
      sqlParams: {
        like: ['deptName']
      },
      searchForm: this.$common.initGridFormData(
        [
          { label: '部门', type: '', model: 'deptName', options: [], col: { span: 8 } }
        ])
    }
  },
  async created () {
    // this.getList()
  },
  methods: {
    show (autographUrl) {
      this.autographUrl = autographUrl
      this.visible = true
    },
    // getList () {
    //   this.$refs.tableBox.getDataSource()
    // },
    actionColumnClick (args) {
      const { btn: { name }, record } = args
      switch (name) {
        case 'handleEdit':
          this.$refs.editSignature.openModal(record)
          break
      }
    },
    onSearchClick ({ btn, formValues }) {
      switch (btn.name) {
        case 'view':
          this.$refs.viewSignature.openModal()
          break
      }
    },
    // 添加用户
    handleAdd: function () {
      this.$refs.uploadSignature.openModal()
    },
    handleEdit: function ({ record }) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '编辑'
      this.$refs.modalForm.disableSubmit = false
    },
    //
    handleCloseModal () {
      this.$refs.tableBox.getDataSource()
    }
  }
}
</script>
